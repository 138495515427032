<template>
  <div>
    <b-table
      striped
      hover
      :items="versionDataList"
      :fields="fields"
      :busy="isLoading"
      show-empty
      responsive
    >
      <template #table-busy>
        <div class="text-center text-secondary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:cell(no)="{ index }">
        {{ (currentPageList - 1) * 10 + index + 1 }}
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="danger" size="sm">
          <template #button-content> Action </template>
          <b-dropdown-item @click="deleteItem(item.id)">
            <feather-icon icon="TrashIcon" class="mr-75" />
            Delete
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-modal
      id="form-post-version"
      centered
      :title="editIdModal != null ? 'Edit Version' : 'Add Version'"
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <ValidationObserver>
        <div class="form">
          <div class="form-group">
            <label>Platform:</label>
            <v-select
              id="group"
              label="name"
              v-model="formPayloadVersion.platform"
              :options="platformList"
              :reduce="(platformList) => platformList.value"
              placeholder="-- Pilih Platform --"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
            <small
              v-for="(validation, index) in validationsForm.platform"
              :key="`errorName${index}`"
              class="text-danger"
              >{{ validation }}</small
            >
          </div>
          <div class="form-group">
            <label for="type">Force Update:</label>
            <div class="demo-inline-spacing" style="margin-top: 8px">
              <div class="custom-control custom-radio mt-0">
                <input
                  name="inlineRadioOptions"
                  class="custom-control-input"
                  type="radio"
                  id="radio1"
                  value="true"
                  v-model="formPayloadVersion.is_force_update"
                />
                <label class="custom-control-label" for="radio1"> Aktif </label>
              </div>
              <div class="custom-control custom-radio mt-0">
                <input
                  name="inlineRadioOptions"
                  class="custom-control-input"
                  type="radio"
                  id="radio2"
                  value="false"
                  v-model="formPayloadVersion.is_force_update"
                />
                <label class="custom-control-label" for="radio2">
                  Tidak Aktif
                </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="notes">Update Notes:</label>
            <validation-provider
              name="notes"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                id="notes"
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                v-model="formPayloadVersion.updated_notes"
                placeholder="Update Notes"
              />
              <small
                v-for="(validation, index) in validationsForm.updated_notes"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
          <div class="form-group">
            <label for="version">Version:</label>
            <validation-provider
              name="version"
              rules="required"
              v-slot="{ errors, classes }"
            >
              <input
                id="version"
                type="text"
                class="form-control"
                :class="classes"
                :state="errors.length > 0 ? false : null"
                v-model="formPayloadVersion.version"
                placeholder="Format: sample 1.1.1"
              />
              <small
                v-for="(validation, index) in validationsForm.version"
                :key="`errorName${index}`"
                class="text-danger"
                >{{ validation }}</small
              >
            </validation-provider>
          </div>
        </div>
        <div v-if="isLoading">
          <br />
          <b-spinner class="mb-2" variant="primary" /><br />
        </div>
        <b-button
          v-if="editIdModal == null"
          variant="primary"
          @click="createItem()"
          v-bind:disabled="isLoading"
        >
          Save Version
        </b-button>
        <b-button
          v-else
          variant="primary"
          @click="updateItem()"
          v-bind:disabled="isLoading"
        >
          Edit Version
        </b-button>
      </ValidationObserver>
    </b-modal>

    <div
      v-if="resultData.itemsPerPage > 0"
      class="mt-3 d-flex justify-content-between align-items-center"
    >
      <small
        >Showing {{ (resultData.currentPage - 1) * 10 + 1 }} to
        {{ (resultData.currentPage - 1) * 10 + 1 * resultData.itemCount }} from
        {{ resultData.totalItems }}</small
      >
      <b-pagination
        class="justify-content-end"
        v-model="currentPageList"
        :total-rows="resultData.totalItems"
        :per-page="resultData.itemsPerPage"
        aria-controls="my-table"
        @change="getData"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BTable,
  BSpinner,
  BBadge,
  BImg,
  BDropdown,
  BPagination,
  BDropdownItem,
  BButton,
  VBModal,
} from "bootstrap-vue";
import _ from "lodash";
export default {
  components: {
    BTable,
    BSpinner,
    BBadge,
    BImg,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    versionData: {
      type: "",
    },
    formPayload: {
      type: "",
    },
    validations: {
      type: "",
    },
    editId: {
      type: "",
    },
    result: {
      type: "",
    },
    isLoading: {
      type: Boolean,
    },
    currentPage: {
      type: Number,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    createItem: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value;
    },
    versionData(value) {
      this.versionDataList = value;
    },
    formPayload(value) {
      this.formPayloadVersion = value;
    },
    validations(value) {
      this.validationsForm = value;
    },
    currentPage(value) {
      this.currentPageList = value;
    },
    result(value) {
      this.resultData = value;
    },
    editId(value) {
      this.editIdModal = value;
    },
    // filter: {
    //   handler(value) {
    //     this.$emit("filter", value);
    //   },
    //   deep: true,
    // },
  },
  data() {
    return {
      required,
      isLoadingComponent: true,
      currentPageList: 1,
      resultData: {},
      versionDataList: [],
      editIdModal: null,
      formPayloadVersion: {
        platform: "",
        is_force_update: "",
        update_notes: "",
        version: "",
      },
      platformList: [
        {
          name: "Android",
          value: "android",
        },
        {
          name: "Ios",
          value: "ios",
        },
      ],
      validationsForm: "",
      fields: [
        { key: "no" },
        { key: "platform" },
        { key: "version" },
        { key: "is_force_update", label: "Force Update" },
        { key: "updated_notes" },
        { key: "actions" },
      ],
      //   filter: {
      //     order_type: "desc",
      //   },
    };
  },
  computed: {
    rows() {
      return this.versionDataList.length;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
