<template>
  <b-card title="Version List">
    <b-row class="mb-3 justify-content-between">
      <b-col cols="12" lg="4" class="d-flex align-items-center">
        <b-button
          @click="cleanUpForm()"
          v-b-modal.form-post-version
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          Create Version
        </b-button>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
        <div class="form-group mb-0">
          <label for="search">Search:</label>
          <input
            id="search"
            type="text"
            class="form-control"
            placeholder="Search: Name"
            v-model="filter.search"
          />
        </div>
      </b-col>
    </b-row>

    <Table
      :result="result"
      :versionData="versionData"
      :is-loading="isLoading"
      :get-data="getData"
      :delete-item="deleteItem"
      :formPayload="formPayload"
      :edit-item="editItem"
      :update-item="updateItem"
      :create-item="createItem"
      :current-page="currentPage"
      :editId="editId"
      :validations="validations"
    />
  </b-card>
</template>

<script>
import Table from "@/components/version/Table.vue";
import { successNotification, errorNotification } from "@/auth/utils";
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BButton,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import _ from "lodash";
export default {
  components: {
    Table,
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormInput,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        this.getData();
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      result: [],
      versionData: {},
      link: "",
      isLoadingExport: false,
      formPayload: {
        platform: "",
        is_force_update: "",
        update_notes: "",
        version: "",
      },
      editId: null,
      filter: {},
      validations: "",
    };
  },
  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        platform: "",
        is_force_update: "",
        update_notes: "",
        version: "",
      };
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.formPayload = {
        platform: item.platform,
        is_force_update: item.is_force_update,
        update_notes: item.update_notes,
        version: item.version,
      };
      this.$bvModal.show("form-post-version");
    },
    createItem() {
      this.isLoading = true;
      this.$http
        .post("/api/v1/admin/versions", this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-post-version");
          this.getData(this.currentPage);
          successNotification(this, "Success", "Version successfully created");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },

    updateItem() {
      this.isLoading = true;
      this.$http
        .patch(
          `/api/v1/admin/post-categories/update/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          this.$bvModal.hide("form-post-version");
          this.getData();
          successNotification(
            this,
            "Success",
            "Version successfully updated!"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
          }
          this.isLoading = false;
        });
    },

    getData(page) {
      this.isLoading = true;
      this.currentPage = page;
      const queryParams = this.filter;
      queryParams.page = page;
      this.$http
        .get("/api/v1/admin/versions", {
          params: queryParams,
        })
        .then((response) => {
          this.result = response.data.data.meta;
          this.versionData = response.data.data.items;
		  console.log(this.versionData, 'data')
          this.currentPage = response.data.data.meta.currentPage;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(slug) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Version?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/versions/${slug}`)
            .then((response) => {
              this.getData(this.currentPage);
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Version successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
